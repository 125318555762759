import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { ErrorsComponent } from './errors/errors.component';
import { ForbiddenComponent } from './forbidden/forbidden.component';
import { MainTemplateComponent } from './main-template/main-template.component';

@NgModule({
  declarations: [MainTemplateComponent, ErrorsComponent, ForbiddenComponent],
  imports: [CommonModule, RouterModule],
  exports: [MainTemplateComponent],
})
export class CoreModule {}
