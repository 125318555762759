// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

// const API_URL = 'https://20.236.195.182/';
const API_URL = 'https://manager-api-qa.appito.com/';

export const environment = {
  production: false,
  pagarmeApi: `${API_URL}api/pagarme`,
  recaptcha: {
    siteKey: '6Lermh8lAAAAAAIDuME6D6CFTHdp-n6Blsa2pcKu',
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import `zone.js/dist/zone-error`;  // Included with Angular CLI.
